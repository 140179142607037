import { MutationTree, ActionTree } from 'vuex';
import {
  EventDto,
  FeatureDto,
  ScheduleDto,
  PageDto,
  getters as eventsGetters,
  isVPSOpen,
  FormType,
  RoomDto,
  TrackDto,
  moment,
} from 'fourwaves-shared';
import { eventsMutations } from 'fourwaves-shared/src/store/events/mutations';
import { RootState, EventsState } from '~/types/state';

export const state = (): EventsState => ({
  eventId: '',
  language: 'browser',
  currentEvent: undefined,
  pages: [],
  sessions: [],
  tracks: [],
  rooms: [],
  currency: 'CAD',
  currentTime: '',
  isWhiteLabel: false,
  isRegistrationCapacityLimited: false,
  isVotingEnabled: true,
  isMediaViewerOpen: false,
  isSidePanelOpen: true,
  isReviewingFeatureEnabled: false,
});

export const getters = {
  ...eventsGetters,
  isVpsOpen: (state: EventsState): boolean => {
    if (!state.currentEvent) return false;
    return isVPSOpen(state.sessions, state.currentTime);
  },
  registrationForm: (state: EventsState) =>
    state.currentEvent!.forms.find(({ type }) => type === FormType.Registration)!,
  submissionForm: (state: EventsState) => state.currentEvent!.forms.find(({ type }) => type === FormType.Submission)!,
};

export const mutations: MutationTree<EventsState> = {
  ...eventsMutations,
  setEventId(state, eventId: string): void {
    state.eventId = eventId;
  },
  setCurrentEvent(state, dto: EventDto): void {
    state.currentEvent = dto;
    state.currency = dto.currency;
  },
  setLanguage(state, language: string): void {
    state.language = language;
  },
  setPages(state, pages: PageDto[] | null): void {
    state.pages = pages || [];
  },
  setSessions(state, sessions: ScheduleDto[] | null): void {
    state.sessions = sessions || [];
  },
  setRooms(state, rooms: RoomDto[] | null): void {
    state.rooms = rooms || [];
  },
  setFeatures(state, features: FeatureDto[] | null): void {
    if (features) {
      state.isWhiteLabel = features.find(x => x.name === 'white_label')!.isEnabled;
      state.isRegistrationCapacityLimited = features.find(x => x.name === 'reg_capacity_limited')!.isEnabled;
      state.isVotingEnabled = features.find(x => x.name === 'voting_enabled')!.isEnabled;
    }
  },
  setTracks(state: EventsState, tracks: TrackDto[]): void {
    state.tracks = tracks || [];
  },
  updateTime(state): void {
    state.currentTime = moment().toISOString();
  },
  setMediaViewerState(state, isOpen: boolean) {
    state.isMediaViewerOpen = isOpen;
  },
  setSidePanelState(state, isOpen: boolean) {
    state.isSidePanelOpen = isOpen;
  },
};

export const actions: ActionTree<EventsState, RootState> = {
  async init(_, slug): Promise<void> {
    await this.dispatch('events/startTimeUpdates');
    return await this.dispatch('events/setEventContext', slug);
  },
  async fetchEvent(context): Promise<EventDto | null> {
    const event = await this.$api.getEvent(context.state.eventId);
    if (event) context.commit('setCurrentEvent', event);
    return event;
  },
  async setEventContext({ commit }, eventId: string): Promise<boolean> {
    this.$api._client.defaults.baseURL = this.$config.API_URL;
    this.$api._client.defaults.headers.common.Authorization = this.$cookies.get('auth._token.local');

    const event = await this.$api.getEvent(eventId);
    if (event) {
      commit('setEventId', event.id);
      commit('setCurrentEvent', event);
      commit('setLanguage', event.language);

      const [pages, sessions, tracks, rooms, features] = await Promise.all([
        this.$api.getPages(event.id, false),
        this.$api.getSessions(event.id),
        this.$api.getTracks(event.id),
        this.$api.getRooms(event.id),
        this.$api.getFeatures(event.id),
      ]);

      commit('setPages', pages);
      commit('setSessions', sessions);
      commit('setTracks', tracks);
      commit('setFeatures', features);
      commit('setSessions', sessions);
      commit('setRooms', rooms);

      return true;
    } else {
      // The event does not exist anymore, or the user is not permitted.
      return false;
    }
  },
  startTimeUpdates({ commit }): void {
    commit('updateTime');
    setInterval(() => {
      commit('updateTime');
    }, 1000 * 10);
  },
};
